import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,} from "@material-ui/core";
import {useEffect, useState} from "react";

export default function InstallDialog(props) {
    const {host, pass, port, authpass, authuser, onClose} = props;

    const [log, setLog] = useState("");

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        var timerLog = 0;
        setLog("");
        if (props.open) {
            if (!host || !pass) {
                setLog("Для выполнении команды нужно заполнить все поля");
                return;
            }
            if (port && !Number(port)){
                setLog("Порт должен быть заполнен цифрами");
                return;
            }

            setLog("");
            if (props.cmd === "check")
                checkConnection(host, pass, (err) => {
                    if (timerLog) clearInterval(timerLog);
                    setLog(err);
                });
            if (props.cmd === "install")
                startInstall(host, pass, port, authpass, authuser, (err) => {
                    if (timerLog) clearInterval(timerLog);
                    setLog(err);
                });

            timerLog = setInterval(() => {
                getLog(
                    host,
                    pass,
                    (txt) => {
                        if (txt) setLog(txt);
                    },
                    (txt) => {
                        if (txt) setLog(txt);
                    }
                );
            }, 500);
        } else {
            if (timerLog) clearInterval(timerLog);
        }
    }, [props.open]);

    return (
        <Dialog fullWidth onClose={handleClose} open={props.open}>
            <DialogTitle>
                <Typography variant="h2" style={{fontSize: "1.2em"}}>
                    {props.title}
                </Typography>
            </DialogTitle>
            <DialogContent style={{backgroundColor: "#171421", color: "#F1F1F1FF"}}>
                <code>
                    {log &&
                        log
                            .split("\n")
                            .map((line) => <p style={{margin: "0"}}>{line}</p>)}
                </code>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function checkConnection(host, pass, onEnd) {
    if (host.lastIndexOf(":") === -1) host += ":22";
    let url =
        "/check?pass=" +
        encodeURIComponent(pass) +
        "&host=" +
        encodeURIComponent(host);

    fetch(url)
        .then((resp) => {
            resp.text().then((txt) => {
                if (txt) onEnd(txt);
            });
        })
        .finally(onEnd);
}

function startInstall(host, pass, port, authpass, authuser, onEnd) {
    if (host.lastIndexOf(":") === -1) host += ":22";

    let url =
        "/install?pass=" +
        encodeURIComponent(pass) +
        "&host=" +
        encodeURIComponent(host) +
        "&port=" +
        encodeURIComponent(port) +
        "&authpass=" +
        encodeURIComponent(authpass) +
        "&authuser=" +
        encodeURIComponent(authuser);

        fetch(url)
            .then((resp) => {
                resp.text().then((txt) => {
                    if (txt) onEnd(txt);
                });
            })
            .finally(onEnd);
}

function getLog(host, pass, onLog, onErr) {
    if (host.lastIndexOf(":") === -1) host += ":22";
    let url =
        "/log?pass=" +
        encodeURIComponent(pass) +
        "&host=" +
        encodeURIComponent(host);

    fetch(url).then(function (response) {
        if (response.status === 200)
            response.text().then((txt) => {
                onLog(txt);
            });
        else
            response.text().then((txt) => {
                onErr(txt);
            });
    });
}
