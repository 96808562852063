import "./Cont.css";
import { Button, Container } from "@material-ui/core";

import { Link, Redirect, Route, Switch } from "react-router-dom";
import ContDonate from "./ContDonate";
import ContInstall from "./ContInstall";
import ContVPSRec from "./ContVPSRec";
import ContVPSDesc from "./ContVPSDesc";
import ContVPSSrch from "./ContVPSSrch";
import ContHome from "./ContHome";

export default function RouterHeader() {
  return (
    <div className="cont">
      <Container className="MainBox" maxWidth="md">
        <div style={{ backgroundColor: "#b0b9fc", textAlign:"center" }}>
          <Button component={Link} to="/home">
            Главная
          </Button>
          <Button component={Link} to="/vpsinfo">
            Vps инфо
          </Button>
          <Button component={Link} to="/vps">
            Установка на VPS
          </Button>
          <Button component={Link} to="/contacts">
            Контакты
          </Button>
        </div>
        <Switch>
          <Route path="/home">
            <ContHome />
          </Route>
          <Route path="/contacts">
            <ContDonate />
          </Route>
          <Route path="/vps">
            <ContInstall />
          </Route>
          <Route path="/vpsinfo">
            <ContVPSRec />
            <ContVPSDesc />
            {/*<ContVPS100 />*/}
            <ContVPSSrch />
          </Route>
          <Redirect from="/" to="/home" />
        </Switch>
      </Container>
    </div>
  );
}
