import "./Cont.css";
import { Container, List, ListItem, Typography } from "@material-ui/core";

export default function ContVPSDesc() {
  return (
    <div className="cont">
      <Container className="MainBox" maxWidth="sm">
        <List>
          <ListItem>
            <Typography variant="h2" style={{ fontSize: "1.2em" }}>
              Зачем нужен VPS?
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <p>
                Дома нет возможности поставить сервер или не тянет оборудование
              </p>
              <p>
                В Вашей стране не законны торренты и за них приходят штрафы. Ваш
                IP будет скрыт так как всю работу с торрентами будет делать VPS
              </p>
              <p>
                Ваш провайдер блокирует торренты, VPS будет качать торрент и
                отдавать вам в виде стрима
              </p>
            </Typography>
          </ListItem>
        </List>
      </Container>
    </div>
  );
}
