import "./Cont.css";
import {Container, List, ListItem, Typography} from "@material-ui/core";
import TelegramIcon from "@material-ui/icons/Telegram";

export default function ContDonate() {
    return (
        <div className="cont">
            <Container className="MainBox" maxWidth="sm">
                <List disablePadding>
                    {/* Donate */}
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            Поддержка проектов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <List disablePadding dense>
                                <ListItem>
                                    <a href="//boosty.to/yourok" target="_blank" rel="noreferrer">Boosty</a>
                                </ListItem>
                                <ListItem>
                                    <a href="//yoomoney.ru/to/410013733697114" target="_blank" rel="noreferrer">YooMoney</a>
                                </ListItem>
                                <ListItem>
                                    <a href="//qiwi.com/n/YOUROK85" target="_blank" rel="noreferrer">QIWI</a>
                                </ListItem>
                                <ListItem>
                                    Сбербанк карта:&nbsp;<u>5484 4000 2285 7839</u>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="h2" style={{fontSize: "1.0em"}}>
                                        Криптовалюта:
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    BTC:&nbsp;<u>19UMMacKKDfjDt45LkG4to9Utkj3vf36Hr</u>
                                </ListItem>
                                <ListItem>
                                    TON:&nbsp;<u>UQCOwFyOdI3p7WCbSuG18SmXg6P6MKY5IvA6iMLEfE9UrfsF</u>
                                </ListItem>
                                <ListItem>
                                    USDT:&nbsp;<u>TNv18QjsAdLyCHtTbQLmSsHFouPJgVfKTG</u>
                                </ListItem>
                                <ListItem>
                                    Крипту можно отправить из телеграма в боте&nbsp;<a href="//t.me/wallet" target="_blank" rel="noreferrer">@wallet</a>&nbsp;из разных стран
                                </ListItem>
                            </List>
                        </Typography>
                    </ListItem>
                    {/* Chats */}
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            Чаты
                        </Typography>
                        <TelegramIcon color="primary" hint="Telegram"/>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <List disablePadding dense>
                                <ListItem>
                                    <a
                                        href="//t.me/YouROK_Programs"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Все мои программы
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a href="//t.me/TorrVPS" target="_blank" rel="noreferrer">
                                        Помощь в установке и настройке TorrServer на виртуальный сервер VPS
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a href="//t.me/TorrServe" target="_blank" rel="noreferrer">
                                        Чат по TorrServe
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="//t.me/NUM_No_UI_Movies"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Чат по NUM, поиск торрентов
                                    </a>
                                </ListItem>
                            </List>
                        </Typography>
                    </ListItem>
                    {/* Links */}
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            Ссылки
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <List disablePadding dense>
                                <ListItem>
                                    <a
                                        href="//github.com/YouROK/TorrServer/releases"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Все версии сервера TorrServer
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="//github.com/YouROK/TorrServe/releases"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Все версии клиента TorrServe (apk)
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="//github.com/yourok-0001/releases1/tree/master/num/release"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Все версии NUM
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="http://book.yourok.ru"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Тут можно почитать книги
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="https://releases.yourok.ru"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Тут можно глянуть что нового вышло из фильмов
                                    </a>
                                </ListItem>
                                <ListItem>
                                    <a
                                        href="https://cutl.top"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Создание коротких ссылок
                                    </a>
                                </ListItem>
                            </List>
                        </Typography>
                    </ListItem>
                </List>
            </Container>
        </div>
    );
}
