import "./Cont.css";
import {Container, List, ListItem, Typography} from "@material-ui/core";

export default function ContHome() {
    return (
        <div className="cont">
            <Container className="MainBox" maxWidth="sm">
                <List disablePadding>
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            TorrServe Android
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <b>TorrServe</b> - это ваш помощник для просмотров торрент-файлов
                            без предварительного скачивания, то есть сразу. Приложение
                            TorrServe позволяет без проблем смотреть фильмы, видеоклипы и
                            слушать mp3-файлы сразу в плеере. Это особенно полезно, если на
                            вашем смартфоне недостаточно места для скачивания фильма или
                            сериала.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            TorrServer Сервер
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <b>TorrServer</b> - это сервер, который делает всю основную
                            работу, позволяет смотреть торренты онлайн. Сервер можно поставить
                            на разные устройства, а клиентом смотреть с него на тв, мобильном
                            устройстве или ПК.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            NUM Android
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <b>NUM</b> - удобная и бесплатная программа для поиска видео
                            контента на торрент-трекерах. NUM показывает новинки фильмов,
                            сериалов, мультфильмов и имеет очень много подборок от разных
                            онлайн кинотеатров.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="h2" style={{fontSize: "1.2em"}}>
                            CUTL.TOP
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            <b>CUTL</b> - С помощью cutl сервиса сокращения ссылок Вы можете
                            сделать из длинной ссылки короткую - сократить или
                            укоротить ссылку. Короткие ссылки удобно отсылать в сообщениях,
                            комментариях или использовать в программах. Короткие URL-адреса
                            cutl.top абсолютно бесплатны, безопасны и очень удобны.{<br/>}
                            Перейти на сайт <a href={"//cutl.top"}>cutl.top</a>
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Ссылки на все программы можно найти в разделе{" "}
                            <a href="/#/contacts">Контакты</a>
                        </Typography>
                    </ListItem>
                </List>
            </Container>
        </div>
    );
}
