import "./Cont.css";
import {
  Button,
  Container,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { useState } from "react";
import InstallDialog from "./InstallDialog";

export default function ContInstall() {
  const [host, setHost] = useState("");
  const [pass, setPass] = useState("");
  const [port, setPort] = useState("8090");
  const [authpass, setAuthPass] = useState("");
  const [authuser, setAuthUser] = useState("");

  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgCmd, setDlgCmd] = useState("");
  const [dlgTitle, setDlgTitle] = useState("");

  const openDialog = (title, cmd) => {
    setDlgTitle(title);
    setDlgCmd(cmd);
    setDlgOpen(true);
  };

  const getTSHost = () => {
    return host.substr(
      0,
      host.indexOf(":") === -1 ? host.length : host.indexOf(":")
    );
  };

  return (
    <div className="cont">
      <Container className="MainBox" maxWidth="sm">
        <List>
          <ListItem>
            <Typography variant="h2" style={{ fontSize: "1.2em" }}>
              Установка TorrServer Matrix
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              Для установки TorrServer Matrix нужно указать IP сервера VPS и
              пароль от root
            </Typography>
          </ListItem>
          <ListItem>
            <TextField
              fullWidth
              name="vps"
              label="IP сервера"
              variant="outlined"
              onChange={(e) => setHost(e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
              type="password"
              fullWidth
              label="Пароль от root vps"
              variant="outlined"
              onChange={(e) => setPass(e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
                type="number"
                fullWidth
                label="Порт TorrServer"
                variant="outlined"
                onChange={(e) => setPort(e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
                fullWidth
                label="Имя пользователя в TorrServer"
                variant="outlined"
                onChange={(e) => setAuthUser(e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
                fullWidth
                type="password"
                label="Пароль пользователя в TorrServer"
                variant="outlined"
                onChange={(e) => setAuthPass(e.target.value)}
            />
          </ListItem>
          <ListItem>
            <Tooltip title="Проверить соединение с VPS" arrow>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  openDialog("Проверка соединения с VPS", "check");
                }}
                endIcon={<SyncAltIcon />}
              >
                Проверить Соединение
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem>
            <Tooltip
              title="Установить TorrServer на Ваш VPS по указанному IP"
              arrow
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  openDialog("Установка TorrServer на VPS", "install");
                }}
                endIcon={<GetAppIcon />}
              >
                Установить TorrServer
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem>
            <Typography style={{ fontSize: "0.7em" }}>
              Если у Вас возникли вопросы или сервер не устанавливается,
              обратитесь в чат по ссылкам из <a href="/#/contacts">Контакты</a>
            </Typography>
          </ListItem>
          <ListItem>
            {host && (
              <Typography style={{ fontSize: "0.7em" }}>
                Адрес TorrServer{" "}
                <a
                  href={"http://" + getTSHost() + ":"+port}
                  target="_blank"
                  rel="noreferrer"
                >
                  {"http://" + getTSHost() + ":"+port}
                </a>
              </Typography>
            )}
          </ListItem>
        </List>
      </Container>

      <InstallDialog
        open={dlgOpen}
        host={host}
        pass={pass}
        port={port}
        authpass={authpass}
        authuser={authuser}
        cmd={dlgCmd}
        title={dlgTitle}
        onClose={() => {
          setDlgOpen(false);
        }}
      />
    </div>
  );
}
