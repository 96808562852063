import "./Cont.css";
import { Container, List, ListItem, Typography } from "@material-ui/core";

export default function ContVPSSrch() {
  return (
    <div className="cont">
      <Container className="MainBox" maxWidth="sm">
        <List>
          <ListItem>
            <Typography variant="h2" style={{ fontSize: "1.2em" }}>
              VPS можно найти на сайтах
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1" style={{ lineHeight: "50%" }}>
              <List
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <ListItem>
                  <a href="//poiskvps.ru" target="_blank" rel="noreferrer">
                    poiskvps.ru
                  </a>
                </ListItem>
                <ListItem>
                  <a href="//vds.menu" target="_blank" rel="noreferrer">
                    vds.menu
                  </a>
                </ListItem>
                <ListItem>
                  <a href="//vps.today" target="_blank" rel="noreferrer">
                    vps.today
                  </a>
                </ListItem>
              </List>
            </Typography>
          </ListItem>
        </List>
      </Container>
    </div>
  );
}
